import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import { Login } from '../models';
import { LoginRepositoryHttp } from '../repositories';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private loginRepositoryHttp: LoginRepositoryHttp,
    private router: Router,
  ) {}

  private logoutEmitter$ = new EventEmitter<any>();

  onLogout(next: () => void): Subscription {
    return this.logoutEmitter$.subscribe(next);
  }

  /**
   * Logs the user into SCS
   *
   * @param username Username
   * @param password Password
   * @returns User login response stream
   */
  login(username: string, password: string): Observable<Login> {
    return this.loginRepositoryHttp.login(username, password);
  }

  loginV4(username: string, password: string): Observable<Login> {
    return this.loginRepositoryHttp.loginV4(username, password);
  }

  isTokenValid(): boolean {
    const token = localStorage.getItem('token');
    if (token) {
      const expiration = JSON.parse(localStorage.getItem('expires_at'));
      return moment().isBefore(moment(expiration));
    }
    return false;
  }

  /**
   * Returns the token if the token is valid
   * @returns string
   */
  getToken(): string {
    return this.isTokenValid() ? localStorage.getItem('token') : undefined;
  }

  getImpersonatedToken(username: string): Observable<string> {
    return this.loginRepositoryHttp.getImpersonatedToken(username);
  }

  async logout(disableNavigation?: boolean): Promise<boolean> {
    try {
      await firstValueFrom(this.loginRepositoryHttp.logout());
    } catch (error) {
      console.error(error);
    }

    localStorage.removeItem('token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('user');
    localStorage.removeItem('imp');
    this.logoutEmitter$.next(undefined);

    if (!disableNavigation) {
      return this.router.navigateByUrl('/login');
    } else {
      return Promise.resolve(true);
    }
  }
}
