import { DeepPath, GeneratedObject, GeneratedObjectTree, I18n, NestedObject } from '../nested-object';

export class TranslationFactory {
  public static getFeatureTranslations<T extends NestedObject, P extends DeepPath<T> | null>(
    translations: T,
    featurePath: P,
  ): GeneratedObjectTree<T, P> {
    const generatedKeyPaths = I18n.generateKeyPath(translations);

    return featurePath?.split('.').reduce<GeneratedObject<T>>((obj, key) => {
      return obj[key] as unknown as GeneratedObject<T>;
    }, generatedKeyPaths) as GeneratedObjectTree<T, P>;
  }
}
