import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { Observable } from 'rxjs';
import { USE_LLT_TOKEN } from '../../interceptor/token.interceptor';
import { InitLog } from '../models/init-log';
import { WebsiteLog } from '../models/website-log';

@Injectable({ providedIn: 'root' })
export class KpiLogRepository {
  constructor(private http: HttpClient) {}

  sendWebsiteEvent(deviceId: string): void {
    const headers = {
      'Content-Type': 'application/json',
    };
    const body: WebsiteLog = {
      DeviceId: deviceId,
    };

    this.http
      .post<null>(environment.kpi.website, body, {
        observe: 'response',
        headers,
      })
      .subscribe((err) => console.log(err));
  }

  sendInitEvent(deviceId: string): Observable<HttpResponse<void>> {
    const headers = {
      'Content-Type': 'application/json',
    };
    const body: InitLog = {
      DeviceId: deviceId,
    };

    return this.http.post<null>(environment.kpi.init, body, {
      observe: 'response',
      headers,
      context: new HttpContext().set(USE_LLT_TOKEN, true),
    });
  }
}
