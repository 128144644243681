import {
  AfterViewInit,
  Component,
  computed,
  HostListener,
  input,
  InputSignal,
  model,
  ModelSignal,
  QueryList,
  signal,
  ViewChild,
  ViewChildren,
  WritableSignal,
} from '@angular/core';
import { Filters, MainFilterOption } from './models';
import {MatSidenav, MatSidenavContainer} from '@angular/material/sidenav';
import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { MatBadge } from '@angular/material/badge';
import { MatChip, MatChipListbox, MatChipRemove } from '@angular/material/chips';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { MatFormField, MatOption, MatSelect } from '@angular/material/select';
import { MatButton } from '@angular/material/button';
import { NgFor, NgIf } from '@angular/common';
import { MatInput } from '@angular/material/input';
import { BaseModule } from '../base';
import { FormsModule } from '@angular/forms';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelDescription,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import { MatCheckbox } from '@angular/material/checkbox';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as enTranslations from './i18n/en-lang.json';
import * as deTranslations from './i18n/de-lang.json';
import { MatDivider } from '@angular/material/divider';

@Component({
  selector: 'spx-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  standalone: true,
  imports: [
    BaseModule,
    FormsModule,
    MatBadge,
    MatButton,
    MatCheckbox,
    MatChip,
    MatChipListbox,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatFormField,
    MatInput,
    MatListOption,
    MatMenu,
    MatMenuItem,
    MatMenuTrigger,
    MatOption,
    MatSelect,
    MatSelectionList,
    MatSidenav,
    NgFor,
    NgIf,
    TranslateModule,
    MatAccordion,
    MatExpansionPanelDescription,
    MatDivider,
    MatChipRemove,
    MatSidenavContainer,
  ],
})
export class FilterComponent implements AfterViewInit {
  public filterOptions: InputSignal<MainFilterOption[]> = input.required<MainFilterOption[]>();
  public filters: ModelSignal<Filters> = model({});

  @ViewChild(MatSidenav) sidenav: MatSidenav;
  @ViewChildren(MatMenu) menus: QueryList<MatMenu>;

  public filterLabels: WritableSignal<Record<string, string>> = signal({});
  public menuRefs: WritableSignal<Record<string, MatMenu | null>> = signal({});
  public openedMenu: WritableSignal<string | undefined> = signal(undefined);
  public isSidenavSwitchingState = signal(false);
  public multiOptionFilters = computed(() => this.filterOptions().filter((o) => !!o.options));
  public showClearAll = computed(() => Object.values(this.filters()).filter((v) => !!v).length > 0);
  public selectedFilterChips = computed(() =>
    Object.entries(this.filters()).reduce((obj, entry) => {
      const selectedFilter = this.filterOptions().find((o) => o.key === entry[0])!;
      entry[1]?.split(',').forEach((filter) => {
        const label = selectedFilter.options ? selectedFilter.options.find((o) => o.key === filter)?.label : selectedFilter.label;
        obj.push([entry[0], label ?? '', filter]);
      });
      return obj;
    }, [] as string[][]),
  );

  constructor(private translate: TranslateService) {
    this.translate.setTranslation('en', enTranslations, true);
    this.translate.setTranslation('de', deTranslations, true);
  }

  @HostListener('window:click', ['$event'])
  public onClick(event: MouseEvent): void {
    if (this.sidenav?.opened && !this.isSidenavSwitchingState() && !this.sidenav._content.nativeElement.contains(event.target as Node)) {
      this.toggleSidenav();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.menuRefs.update((refs) => {
        this.multiOptionFilters().forEach((option, index) => {
          refs[option.key] = this.menus?.toArray()[index] || null;
        });
        return refs;
      });
    });

    this.sidenav._closedStream.subscribe(() => this.isSidenavSwitchingState.set(false));
    this.sidenav._openedStream.subscribe(() => this.isSidenavSwitchingState.set(false));
  }

  public toggleFilter(key: string, value: string | boolean) {
    if (!key) {
      return;
    }

    const { options } = this.filterOptions().find((o) => o.key === key)!;

    const selected = this.filters()[key]?.split(',') || [];

    const filters = this.filters();
    if (typeof value === 'boolean') {
      if (value) {
        filters[key] = 'true';
      } else {
        delete filters[key];
      }
    } else {
      const filterArray = selected.includes(value) ? selected.filter((v) => v !== value) : [...selected, value];
      if (filterArray.length > 0) {
        filters[key] = filterArray.join(',');
      } else {
        delete filters[key];
      }
    }

    if (options) {
      this.adjustExpansionPanelDescriptions(key);
    }

    this.filters.set({ ...filters });
  }

  public clearFilters() {
    this.filters.set({});
    this.filterLabels.set({});
    this.filters.set(this.filters());
  }

  public toggleSidenav(): void {
    if (this.sidenav.opened) {
      this.sidenav.close();
    } else {
      this.sidenav.open();
    }
    this.isSidenavSwitchingState.set(true);
  }

  public adjustExpansionPanelDescriptions(key: string): void {
    const selected = this.filters()[key]?.split(',') || [];

    this.filterLabels.update((labels) => {
      const options = this.filterOptions().find((o) => o.key === key)?.options;
      labels[key] = selected.map((s) => options?.find((o) => o.key === s)?.label).join(', ');
      return labels;
    });
  }
}
