import { DeepPath, SubTree } from '../nested-object';
import { NestedEndpoints } from './nested-endpoints';

export class EndpointsFactory {
  private static generateEndpoints(endpoints: NestedEndpoints, prefix: string): NestedEndpoints {
    const mapEndpoints = (obj: NestedEndpoints, pathPrefix: string): NestedEndpoints => {
      return Object.keys(obj).reduce((acc, key) => {
        const value = obj[key];

        if (typeof value === 'string') {
          acc[key] = `${pathPrefix}/${value}`;
        } else if (typeof value === 'function') {
          acc[key] = (...params: string[]) => {
            const result = value(...params);
            return result.length > 0 ? `${pathPrefix}/${result}` : pathPrefix;
          };
        } else {
          acc[key] = mapEndpoints(value, `${pathPrefix}/${key}`);
        }
        return acc;
      }, {} as NestedEndpoints);
    };

    return mapEndpoints(endpoints, prefix);
  }

  private static extractFeatureEndpoints<T extends NestedEndpoints, P extends DeepPath<T>>(api: T, featurePath: P): SubTree<T, P> {
    return featurePath.split('.').reduce((obj: NestedEndpoints | undefined, key: string) => {
      if (obj && typeof obj === 'object' && key in obj) {
        return obj[key];
      }
      throw new Error(`Invalid feature path: "${featurePath}". Key "${key}" not found.`);
    }, api) as SubTree<T, P>;
  }

  public static getFeatureEndpoints<T extends NestedEndpoints, P extends DeepPath<T> | null = null>(
    api: T,
    apiPrefix: (string | undefined)[],
    featurePath: P | null = null,
  ): SubTree<T, P> {
    let prefix = `${apiPrefix?.filter(Boolean).join('/')}`;
    if (prefix && !prefix.startsWith('/') && !prefix.startsWith('http')) {
      prefix = `/${prefix}`;
    }

    const generatedEndpoints = this.generateEndpoints(api, prefix);
    if (featurePath) {
      return this.extractFeatureEndpoints(generatedEndpoints as T, featurePath);
    }

    return generatedEndpoints as SubTree<T, P>;
  }
}
