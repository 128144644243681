import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ThemingModule } from '@ird/ng-base';
import { LocaleService, UserService } from '@ird/ng-common';
import { PwaModule } from '@ird/ng-pwa';
import { FaroService, PricespiderConfigLoader, PricespiderModule } from '@ird/ng-tools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { UnleashService } from './core/services/unleash.service';
import { HttprestInterceptor } from './interceptor/httprest.interceptor';
import { LanguageInterceptor } from './interceptor/language.interceptor';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { LayoutModule } from './layout/layout.module';
import {
  PricespiderFactory,
  appInitializerFactory,
  faroInitializerFactory,
  localeImportProviderFactory,
  localeProviderFactory,
  userProviderFactory,
} from './providers';
import { AppService } from './shared/services/app.service';
import { DeviceService } from './shared/services/device.service';
import { SpUserService } from './shared/services/sp-user.service';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    LayoutModule,
    SharedModule,
    CoreModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      extend: true,
    }),
    BrowserAnimationsModule,
    AppRoutingModule,
    ThemingModule,
    RecaptchaV3Module,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: document.location.protocol.startsWith('https'),
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NgxGpAutocompleteModule.forRoot({
      loaderOptions: {
        apiKey: 'AIzaSyALiO5GOQmyAUM26-TunafwcIvqd1orPuQ',
        libraries: ['places'],
      },
    }),
    GoogleTagManagerModule.forRoot({
      id: 'GTM-TT42S6M',
    }),
    PwaModule.forRoot({
      checkForUpdate: 15 * 60 * 1000,
    }),
    PricespiderModule.forRoot({
      provide: PricespiderConfigLoader,
      useFactory: (unleashService: UnleashService) => {
        return new PricespiderFactory(unleashService);
      },
      deps: [UnleashService],
    }),
  ],
  exports: [TranslateModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttprestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true,
    },
    {
      provide: UserService,
      useExisting: SpUserService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: userProviderFactory,
      multi: true,
      deps: [SpUserService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [AppService, DeviceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: localeImportProviderFactory,
      multi: true,
      deps: [LocaleService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: faroInitializerFactory,
      multi: true,
      deps: [FaroService, UnleashService],
    },
    {
      provide: LOCALE_ID,
      useFactory: localeProviderFactory,
      deps: [LocaleService],
    },
    {
      provide: MAT_DATE_LOCALE,
      useFactory: localeProviderFactory,
      deps: [LocaleService],
    },

    { provide: 'ENVIRONMENT', useValue: environment },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaSiteKey,
    },
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '-lang.json');
}
