/**
 * A nested object structure where each leaf node is replaced with a string representing its full path.
 * Each object also contains a `_self` property, which holds the full path to the current object.
 *
 * @template T - The type of the input object.
 */
export type NestedObject = { [key: string]: NestedObject | string };

/**
 * A key path structure which contains all keys to the same shape as the input but with leaf nodes as string paths.
 * Each object also contains a `_self` property.
 *
 * @template T - The type of the input object.
 */
export type KeyPath = {
  /**
   * The full path to the current object in the JSON hierarchy.
   * @returns the full path to the current object
   *
   * @example
   *
   * const inputJSON = {
   *   "DOMAIN": {
   *     "SUB_DOMAIN": {
   *       "FEATURE": {
   *         "TITLE": "My Title"
   *       }
   *     }
   *   }
   * };
   * const outputJSON = I18n.generateKeyPath(inputJSON).DOMAIN.SUB_DOMAIN.FEATURE;
   * console.log(outputJSON._self); //"DOMAIN.SUB_DOMAIN.FEATURE"
   */
  _self: string;
};

/**
 * Represents a typesafe path into the JSON structure
 */
export type DeepPath<T> = T extends object
  ? {
      [K in keyof T]: K extends string ? (T[K] extends object ? `${K}` | `${K}.${DeepPath<T[K]>}` : `${K}`) : never;
    }[keyof T]
  : never;

/**
 * Represents a subtree of the JSON structure
 */
export type SubTree<T, Path extends string | null | undefined> = Path extends null
  ? T
  : Path extends `${infer Key}.${infer Rest}`
  ? Key extends keyof T
    ? Rest extends string
      ? SubTree<T[Key], Rest>
      : never
    : never
  : Path extends keyof T
  ? T[Path]
  : never;

/**
 * A generated object structure with the same shape as the input but with leaf nodes as string paths.
 * Each object also contains a `_self` property.
 *
 * @template T - The type of the input object.
 */
export type GeneratedObject<T> = T extends object
  ? {
      [K in keyof T]: T[K] extends object ? GeneratedObject<T[K]> & KeyPath : string;
    } & KeyPath
  : never;

export type GeneratedObjectTree<T, P extends string | null | undefined> = SubTree<GeneratedObject<T>, P>;

export class I18n {
  private static constructKeyPath(keys: string[]): string {
    return keys.join('.');
  }

  private static traverseAndGenerate<U>(obj: U, path: string[] = []): string | KeyPath | Partial<GeneratedObject<U>> | GeneratedObject<U> {
    if (typeof obj !== 'object' || obj === null) {
      return this.constructKeyPath(path);
    }

    const result: KeyPath | Partial<GeneratedObject<U>> = path?.length > 0 ? { _self: this.constructKeyPath(path) } : {};
    for (const key of Object.keys(obj)) {
      result[key] = this.traverseAndGenerate(obj[key], [...path, key]);
    }
    return result;
  }

  /**
   * Generates a new object with the same structure as the input object, where:
   * - Each leaf value is replaced with a string representing its full path in the input.
   * - Each object contains a `_self` property that provides the full path to that object.
   *
   * @template T - The type of the input object.
   * @param input - The nested input object to transform.
   * @returns A new object with paths as leaf values and `_self` properties in each object.
   *
   * @example
   *
   * const inputJSON = {
   *   "DOMAIN": {
   *     "SUB_DOMAIN": {
   *       "FEATURE": {
   *         "TITLE": "My Title"
   *       }
   *     }
   *   }
   * };
   * const outputJSON = generateTranslatedJSON(inputJSON);
   * console.log(outputJSON);
   * // {
   * //   DOMAIN: {
   * //     _self: "DOMAIN",
   * //     SUB_DOMAIN: {
   * //       _self: "DOMAIN.SUB_DOMAIN",
   * //       FEATURE: {
   * //         _self: "DOMAIN.SUB_DOMAIN.FEATURE",
   * //         TITLE: "DOMAIN.SUB_DOMAIN.FEATURE.TITLE",
   * //       }
   * //     }
   * //   }
   * // }
   */
  public static generateKeyPath<T extends NestedObject>(input: T): GeneratedObject<T> {
    return this.traverseAndGenerate<T>(input) as GeneratedObject<T>;
  }
}
