export const SpxBreakpoints = {
  'lt-sm': '(max-width: 599px)',
  'lt-md': '(max-width: 959px)',
  'lt-lg': '(max-width: 1279px)',
  'lt-xl': '(max-width: 1919px)',
  'gt-xs': '(min-width: 600px)',
  'gt-sm': '(min-width: 960px)',
  'gt-md': '(min-width: 1280px)',
  'gt-lg': '(min-width: 1920px)',
};
