// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: flex-end;
  padding: 12px;
}
:host spx-button {
  width: 40px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBhZ2luYXRvci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLGFBQUE7RUFDQSxtQkFBQTtFQUNBLFFBQUE7RUFDQSx5QkFBQTtFQUNBLGFBQUE7QUFDRjtBQUNFO0VBQ0UsV0FBQTtBQUNKIiwiZmlsZSI6InBhZ2luYXRvci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjpob3N0IHtcbiAgZGlzcGxheTogZmxleDtcbiAgZmxleC1kaXJlY3Rpb246IHJvdztcbiAgZ2FwOiA0cHg7XG4gIGp1c3RpZnktY29udGVudDogZmxleC1lbmQ7XG4gIHBhZGRpbmc6IDEycHg7XG5cbiAgc3B4LWJ1dHRvbiB7XG4gICAgd2lkdGg6IDQwcHg7XG4gIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./libs/shared/ng/base/src/lib/paginator/paginator.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,yBAAA;EACA,aAAA;AACF;AACE;EACE,WAAA;AACJ;AACA,oiBAAoiB","sourcesContent":[":host {\n  display: flex;\n  flex-direction: row;\n  gap: 4px;\n  justify-content: flex-end;\n  padding: 12px;\n\n  spx-button {\n    width: 40px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
