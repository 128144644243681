import { User } from '../user/user.model';
import { Organization } from './organization.model';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
/**
 * TODO - while Migrating Below "Tenant" lets migrate deprecated User to UserDao & Userdto concept
 * This is only model using deprecated User, rest all places are currently using UserDao from @ird/shared-api-interfaces
 * We are keeping this because of time constraint as it needs further more models migrations
 */
export interface Tenant {
  id: string;
  name: string;
  organisations: Organization[];
  childCount?: number;
  admins?: User[];
}
