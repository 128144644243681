import { Component, ElementRef, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Color } from '../../model/color';

@Component({
  selector: 'spx-spinner',
  templateUrl: 'spinner.component.html',
  styleUrls: ['spinner.component.scss'],
  standalone: false,
})
export class SpinnerComponent implements OnInit, OnChanges, OnDestroy {
  /**
   * One of three sizes of the spinner component,
   * On default the size should be big, as it is the most used one
   */
  @Input()
  size: 'INLINE' | 'BUTTON' | 'SMALL' | 'MEDIUM' | 'BIG' = 'BIG';

  /**
   * Type of the icon button
   */
  @HostBinding('class.overlay')
  @Input()
  overlay = false;

  /**
   * Determines if spinner should be displayed
   */
  @HostBinding('class.display')
  public display = true;

  @Input()
  public color: Color = 'primary';

  /**
   * Subscription variable;
   * allows unsubscribing observable on destroy component
   */
  @Input()
  public subscription?: Subscription;

  /**
   * Numeric value of the spinner diameter
   */
  public diameter!: number;

  constructor(private elementRef: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['subscription']?.currentValue) {
      this.display = true;
      this.subscriptionHandler();
    }
  }

  ngOnInit(): void {
    switch (this.size) {
      case 'INLINE':
        this.diameter = this.getContentSize();
        break;
      case 'BIG':
        this.diameter = 70;
        break;
      case 'MEDIUM':
        this.diameter = 50;
        break;
      case 'BUTTON':
        this.diameter = 14;
        break;
      case 'SMALL':
      default:
        this.diameter = 30;
        break;
    }

    this.subscriptionHandler();
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  subscriptionHandler() {
    if (this.subscription) {
      this.subscription.add(() => {
        this.display = false;
        this.subscription?.unsubscribe();
        this.subscription = undefined;
      });
    }
  }

  private getContentSize(): number {
    const parent = this.elementRef.nativeElement.parentElement;
    const fontSize = window.getComputedStyle(parent).getPropertyValue('font-size');
    return parseFloat(fontSize);
  }
}
