import { Pageable } from '../pageable';
import { PagedData } from '../paged-data';
import { UUID } from '../uuid';

/**
 * @see https://gitlab.seepex.com/scs/libs/irco-sdk-java/-/blob/master/irco-sdk-rpc/src/main/kotlin/com/irco/rpc/CrudRpc.kt
 */
export interface CrudRepository<T> {
  /**
   * Create
   *
   * Creates a resource.
   * The property 'id' in the resource must be null for the operation.
   *
   * @param resource the resource to be created.
   * @return the resource created.
   * @see: POST /resource HTTP/1.1
   */
  create(_query: { resource: Partial<T> }): Promise<T>;

  /**
   * Get by ID
   *
   * Finds the resource by the resource identifier.
   *
   * @param id the resource identifier
   * @return the resource or null when no resource is found.
   * @see: GET /resource/id HTTP/1.1
   */
  get(_query: { id: UUID }): Promise<T | null>;

  /**
   * Get All
   *
   * Finds all resources matching a query.
   *
   * @param query the matching criteria, null or empty string for no matching criteria.
   * @param pageable the paging and sorting.
   * @see: GET /resource?q= HTTP/1.1
   */
  getAll(_query: { query: string | null; pageable: Pageable }): Promise<PagedData<T>>;

  /**
   * Update
   *
   * Updates a resource.
   * The property 'id' in the resource is required for the operation.
   *
   * @param resource the resource to be updated
   * @param patch boolean option to update only the non-null values passed. default value: false.
   * @return the resource updated
   * @see: PUT /resource HTTP/1.1 | PATCH /resource HTTP/1.1
   */
  update(_query: { resource: Partial<T>; patch?: boolean }): Promise<T>;

  /**
   * Patch
   *
   * Patches a resource. Will update only the non-null values passed.
   * The property 'id' in the resource is required for the operation.
   *
   * @param resource the resource to be patched
   * @return the resource patched
   * @see: PATCH /resource HTTP/1.1
   */
  patch(_query: { resource: Partial<T>; patch?: boolean }): Promise<T>;

  /**
   * Delete
   *
   * Deletes a resource by the resource identifier.
   *
   * @param id the identifier of the resource to be deleted.
   * @return the deleted resource, or null when not present.
   * @see: DELETE /resource/id HTTP/1.1
   */
  delete(_query: { id: UUID }): Promise<UUID | null>;

  /**
   * Delete All by Ids
   *
   * Deletes a collection of resources by the resource identifiers.
   *
   * @param ids the identifiers of the resources to be deleted.
   * @return the resources ids that failed be deleted.
   * @see: DELETE /resource/id HTTP/1.1
   */
  deleteAll(_query: { ids: UUID[] }): Promise<UUID[] | null>;
}
