import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TenantSettings } from '@deprecated/api-interfaces';
import { UUID } from '@ird/shared-base';
import { DeviceService, FileService, TenantService } from '../../services';

@Component({
  selector: 'shared-device-image',
  templateUrl: './device-image.component.html',
  styleUrls: ['./device-image.component.scss'],
  standalone: false,
})
export class DeviceImageComponent implements OnInit, OnChanges {
  imageSource: any;

  @Input()
  type: 'thumbnail' | 'picture' = 'thumbnail';
  @Input()
  device!: { id: UUID; thumbnailId?: UUID; pictureId?: UUID; type?: string };
  @Input()
  imageClass!: string;
  @Input()
  canClick = true;
  @Input()
  imageId!: string;
  @Input()
  imageUrl!: string;

  @Output() imageClick = new EventEmitter<any>();

  @ViewChild('deviceImage')
  deviceImage!: ElementRef<HTMLImageElement>;

  private tenantSettings!: TenantSettings;

  constructor(
    private fileService: FileService,
    private deviceService: DeviceService,
    private tenantService: TenantService,
    private sanitizer: DomSanitizer,
  ) {}

  async ngOnChanges(changes: SimpleChanges) {
    const isDifferentDevice = changes.device?.currentValue?.id !== changes.device?.previousValue?.id;
    const isDifferentImage =
      !this.imageId ||
      (this.imageId !== changes.device?.previousValue?.pictureId && this.imageId !== changes.device?.previousValue?.thumbnailId);

    if (this.device && (isDifferentDevice || isDifferentImage)) {
      this.imageSource = await this.getDeviceImage(this.tenantSettings);
      if (this.deviceImage) {
        this.deviceImage.nativeElement.src = this.imageSource;
      }
    }
  }

  ngOnInit(): void {
    this.tenantService.changeSettingsEvent().subscribe(async (tenantSettings: TenantSettings) => {
      this.tenantSettings = tenantSettings;
      if (tenantSettings && this.device) {
        const previewDeviceImage = this.deviceService.getPreviewDefaultDeviceImage();

        if (previewDeviceImage) {
          const objectUrl = URL.createObjectURL(previewDeviceImage);
          this.imageSource = this.sanitizer.bypassSecurityTrustUrl(objectUrl);
        } else {
          this.imageSource = await this.getDeviceImage(tenantSettings);
        }

        if (this.deviceImage) {
          this.deviceImage.nativeElement.src = this.imageSource;
        }
      }
    });
  }

  async getDeviceImage(tenantSettings: TenantSettings): Promise<string> {
    const showImage = !!this.device?.thumbnailId || !!this.device?.pictureId;

    if (showImage) {
      if (this.type === 'picture') {
        return this.fileService.getDeviceLargeImage(this.device.id, this.device.pictureId);
      } else {
        return this.fileService.getDeviceThumbnailImage(this.device.id, this.device.thumbnailId);
      }
    } else {
      return this.deviceService.getDefaultImage(tenantSettings, this.device);
    }
  }
}
