/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class RecipientSettings {
  id?: string;
  allNotificationsEmailRecipients: string[] = [];
  allNotificationsSmsRecipients: string[] = [];
  infoNotificationsEmailRecipients: string[] = [];
  infoNotificationsSmsRecipients: string[] = [];
  warningNotificationsEmailRecipients: string[] = [];
  warningNotificationsSmsRecipients: string[] = [];
  errorNotificationsEmailRecipients: string[] = [];
  errorNotificationsSmsRecipients: string[] = [];
  safetyStopNotificationsEmailRecipients: string[] = [];
  safetyStopNotificationsSmsRecipients: string[] = [];
  statusReportNotificationsEmailRecipients: string[] = [];
}
