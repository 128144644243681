import { Pipe, PipeTransform } from '@angular/core';
import { isoLanguage } from '@ird/shared-utils';

@Pipe({
    name: 'languageCode',
    standalone: false
})
export class LanguageCodePipe implements PipeTransform {
  transform(value: string): string {
    isoLanguage.forEach((language) => {
      if (language.code === value.toLowerCase()) {
        value = language.name;
      }
    });
    return value;
  }
}
