import { UUID } from '../uuid';

export interface DataEvent {
  assetId: UUID;
  tenantId: UUID;

  sourceId: UUID;
  sourceType: DataSource;

  value: number | string;

  timeInNanoSeconds: number;
  receivedTimeInNanoSeconds: number;
}

export enum DataSource {
  FORMULA = 'FORMULA',
  SENSOR = 'SENSOR',
}
