import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { inject, LOCALE_ID, NgModule, provideAppInitializer } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Loader } from '@googlemaps/js-api-loader';
import { GooglePlacesAutocompleteDirective, NotificationModule, ThemingModule } from '@ird/ng-base';
import { LocaleService, TenantService, UserService } from '@ird/ng-common';
import { PwaModule } from '@ird/ng-pwa';
import { FaroService, PricespiderConfigLoader, PricespiderModule } from '@ird/ng-tools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha-2';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { UnleashService } from './core/services/unleash.service';
import { HttprestInterceptor } from './interceptor/httprest.interceptor';
import { LanguageInterceptor } from './interceptor/language.interceptor';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { LayoutModule } from './layout/layout.module';
import {
  appInitializerFactory,
  faroInitializerFactory,
  localeImportProviderFactory,
  localeProviderFactory,
  PricespiderFactory,
  userProviderFactory,
} from './providers';
import { AppService } from './shared/services/app.service';
import { DeviceService } from './shared/services/device.service';
import { SpUserService } from './shared/services/sp-user.service';
import { TenantConfigService } from './shared/services/tenant-config.service';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    GooglePlacesAutocompleteDirective,
    BrowserModule,
    TranslateModule.forRoot({
      extend: true,
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'en',
    }),
    LayoutModule,
    SharedModule,
    CoreModule,
    HttpClientModule,
    NotificationModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ThemingModule,
    RecaptchaV3Module,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: document.location.protocol.startsWith('https'),
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    GoogleTagManagerModule.forRoot({
      id: 'GTM-NWLZ9P29', //'GTM-TT42S6M',
    }),
    PwaModule.forRoot({
      checkForUpdate: 15 * 60 * 1000,
    }),
    PricespiderModule.forRoot({
      provide: PricespiderConfigLoader,
      useFactory: (unleashService: UnleashService, tenantConfigService: TenantConfigService, tenantService: TenantService) => {
        return new PricespiderFactory(unleashService, tenantConfigService, tenantService);
      },
      deps: [UnleashService, TenantConfigService, TenantService],
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttprestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true,
    },
    {
      provide: UserService,
      useExisting: SpUserService,
    },
    provideAppInitializer(() => {
      const initializerFn = userProviderFactory(inject(SpUserService));
      return initializerFn();
    }),
    provideAppInitializer(() => {
      const initializerFn = appInitializerFactory(inject(AppService), inject(DeviceService));
      return initializerFn();
    }),
    provideAppInitializer(() => {
      const initializerFn = localeImportProviderFactory(inject(LocaleService));
      return initializerFn();
    }),
    provideAppInitializer(() => {
      const initializerFn = faroInitializerFactory(inject(FaroService), inject(UnleashService));
      return initializerFn();
    }),
    {
      provide: LOCALE_ID,
      useFactory: localeProviderFactory,
      deps: [LocaleService],
    },
    {
      provide: MAT_DATE_LOCALE,
      useFactory: localeProviderFactory,
      deps: [LocaleService],
    },
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: 'AIzaSyALiO5GOQmyAUM26-TunafwcIvqd1orPuQ',
        libraries: ['places'],
      }),
    },
    { provide: 'ENVIRONMENT', useValue: environment },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaSiteKey,
    },
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '-lang.json');
}
