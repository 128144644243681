import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseModule } from '@ird/ng-base';
import { TranslateModule } from '@ngx-translate/core';
import {
  ColorPickerComponent,
  ColorPickerInputComponent,
  DeviceDetailTitleComponent,
  DeviceIconsComponent,
  DeviceImageComponent,
  FileUploaderComponent,
  ImageSliderComponent,
  MetadataComponent,
  ModelAutocompleteComponent,
  OverlayButtonComponent,
  PasswordInputComponent,
  PermissionOverlayComponent,
  SearchBarComponent,
  SearchOptionDirective,
  UploadDialogComponent,
  WarningPanelComponent,
} from './components';
import { CopyContentButtonComponent } from './components/copy-content-button/copy-content-button.component';
import { ExportCSVSnackbarComponent } from './components/export-csv-snackbar/export-csv-snackbar.component';
import { TreePathBreadcrumpsComponent } from './components/tree-path-breadcrumbs/tree-path-breadcrumbs.component';
import { DragAndDropFileDirective, ImpersonateDirective, NavTabDirective } from './directives';
import { MaterialModule } from './material.module';
import { AllowedPipe } from './pipes/allowed.pipe';
import { ArrayJoinPipePipe } from './pipes/arrayJoin.pipe';
import { MatchCypressPipe } from './pipes/match-cypress.pipe';
import {
  ActionsHttpRepository,
  DataConversionRepositoryHttp,
  DeviceConfigRepositoryHttp,
  DeviceModelRepositoryHttp,
  DeviceRepositoryHttp,
  DeviceViewConfigRepositoryHttp,
  FileRepository,
  FileRepositoryUi,
  LoginRepositoryHttp,
  MaintenanceReportRepositoryHttp,
  TenantRepositoryHttp,
  TenantSettingsRepositoryHttp,
  UserRepositoryHttp,
} from './repositories';
import { ColorPickerModule } from 'ngx-color-picker';
import { IconPickerComponent } from './components/icon-picker/icon-picker.component';
import { PlaceholderImageComponent } from './components/placeholder-image/placeholder-image.component';
import { CountryService, FileService, GooglePlacesService, ImageSliderService, MaintenanceReportService } from './services';

@NgModule({
  declarations: [
    MetadataComponent,
    NavTabDirective,
    UploadDialogComponent,
    FileUploaderComponent,
    DragAndDropFileDirective,
    SearchBarComponent,
    SearchOptionDirective,
    OverlayButtonComponent,
    PermissionOverlayComponent,
    AllowedPipe,
    PasswordInputComponent,
    WarningPanelComponent,
    DeviceDetailTitleComponent,
    DeviceImageComponent,
    MatchCypressPipe,
    ArrayJoinPipePipe,
    ModelAutocompleteComponent,
    DeviceIconsComponent,
    ImpersonateDirective,
    CopyContentButtonComponent,
    TreePathBreadcrumpsComponent,
    ImageSliderComponent,
    ExportCSVSnackbarComponent,
    PlaceholderImageComponent,
    ColorPickerComponent,
    ColorPickerInputComponent,
    IconPickerComponent,
    PlaceholderImageComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    BaseModule,
    TranslateModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    ClipboardModule,
    ColorPickerModule,
  ],
  exports: [
    MaterialModule,
    MetadataComponent,
    NavTabDirective,
    UploadDialogComponent,
    FileUploaderComponent,
    DragAndDropFileDirective,
    SearchBarComponent,
    SearchOptionDirective,
    OverlayButtonComponent,
    PermissionOverlayComponent,
    AllowedPipe,
    PasswordInputComponent,
    WarningPanelComponent,
    DeviceDetailTitleComponent,
    TranslateModule,
    DeviceImageComponent,
    MatchCypressPipe,
    ArrayJoinPipePipe,
    ModelAutocompleteComponent,
    DeviceIconsComponent,
    ImpersonateDirective,
    CopyContentButtonComponent,
    TreePathBreadcrumpsComponent,
    ImageSliderComponent,
    ExportCSVSnackbarComponent,
    ColorPickerComponent,
    ColorPickerInputComponent,
    IconPickerComponent,
  ],
  providers: [
    AllowedPipe,
    FileService,
    FileRepository,
    ImageSliderService,
    ActionsHttpRepository,
    DeviceConfigRepositoryHttp,
    DeviceModelRepositoryHttp,
    DeviceViewConfigRepositoryHttp,
    DeviceRepositoryHttp,
    FileRepositoryUi,
    FileRepository,
    FileService,
    GooglePlacesService,
    CountryService,
    LoginRepositoryHttp,
    TenantSettingsRepositoryHttp,
    TenantRepositoryHttp,
    UserRepositoryHttp,
    DataConversionRepositoryHttp,
    MaintenanceReportService,
    MaintenanceReportRepositoryHttp,
  ],
})
export class SharedDomainNgModule {}
