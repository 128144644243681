import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateRange } from '@angular/material/datepicker';
import moment, { Moment } from 'moment';
import { MaxRange, TimeRangeType } from './models';

const FORMAT = 'llll';
const CALENDAR_FORMAT = 'LL';

@Component({
  selector: 'spx-range-selector-input',
  templateUrl: './range-selector-input.component.html',
  styleUrls: ['./range-selector-input.component.scss'],
  standalone: false,
})
export class RangeSelectorInputComponent implements OnInit, OnChanges {
  @Input() dateRangeInput!: DateRange<Moment>;
  @Input() maxDate?: Moment;
  @Input() minDate?: Moment;
  @Input() onlyCalendar = false;
  @Input() configuredOptions?: Array<TimeRangeType>;
  @Input() maxRange?: MaxRange;

  @Output() dateRangeChange: EventEmitter<DateRange<Moment>> = new EventEmitter<DateRange<Moment>>();

  @ViewChild('dateInput') dateInput?: HTMLInputElement;

  public isOpen = false;
  public originalDateRange: DateRange<Moment> = new DateRange<Moment>(null, null);
  public range = '';
  public rangeForm: FormGroup = new FormGroup({});
  public dateFormat = FORMAT;
  public rangeType = { current: TimeRangeType.CUSTOM, original: TimeRangeType.CUSTOM };
  public quantity = { current: 0, original: 0 };
  public focused: 'name' | 'date' = 'date';
  public inputWidth?: string;
  public dateRange: DateRange<Moment> = new DateRange<Moment>(null, null);
  public positions = [new ConnectionPositionPair({ originX: 'start', originY: 'bottom' }, { overlayX: 'start', overlayY: 'top' })];

  private static dateRangeValidator(
    control: AbstractControl,
    maxRange?: MaxRange,
    min?: Moment,
    max?: Moment,
    onlyCalendar?: boolean,
  ): { invalid?: boolean; hierarchy?: boolean; maxRange?: boolean; min?: boolean; max?: boolean } | null {
    const dateInvalid = { invalid: true };

    if (!control || !control.value) {
      return dateInvalid;
    }

    const dates = control.value.split(' - ');

    if (dates?.length !== 2) {
      return dateInvalid;
    }

    const firstDate = moment(dates[0]);
    const secondDate = moment(dates[1]);

    if (!firstDate.isValid() || !secondDate.isValid()) {
      return dateInvalid;
    }

    if (firstDate.isAfter(secondDate)) {
      return { hierarchy: true };
    }

    if (onlyCalendar && secondDate.isSame(firstDate, 'day')) {
      return { hierarchy: true };
    }

    if (maxRange && secondDate.diff(firstDate, maxRange.type) > maxRange.quantity) {
      return { maxRange: true };
    }

    if (min && firstDate.isBefore(min)) {
      return { min: true };
    }

    if (max && secondDate.isAfter(max)) {
      return { max: true };
    }
    return null;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dateRangeInput?.currentValue?.start) {
      this.dateRange = new DateRange<Moment>(this.dateRangeInput.start, this.dateRangeInput.end);
      this.originalDateRange = new DateRange(this.dateRange.start, this.dateRange.end);
      this.rangeForm
        .get('range')
        ?.setValue(this.dateRange.start?.format(this.dateFormat) + ' - ' + this.dateRange.end?.format(this.dateFormat));
    }
  }

  ngOnInit() {
    if (this.onlyCalendar) {
      this.dateFormat = CALENDAR_FORMAT;
    }

    if (this.dateRangeInput) {
      this.dateRange = new DateRange<Moment>(moment(this.dateRangeInput.start), moment(this.dateRangeInput.end));
    } else if (this.maxDate && this.maxDate.isBefore(moment())) {
      this.dateRange = new DateRange<Moment>(moment(this.maxDate).subtract(1, 'day'), moment(this.maxDate));
    } else {
      this.dateRange = new DateRange<Moment>(moment().subtract(1, 'day'), moment());
    }

    this.originalDateRange = new DateRange(this.dateRange.start, this.dateRange.end);
    this.rangeForm.addControl(
      'range',
      new FormControl(this.dateRange.start?.format(this.dateFormat) + ' - ' + this.dateRange.end?.format(this.dateFormat), [
        Validators.required,
        (control) => RangeSelectorInputComponent.dateRangeValidator(control, this.maxRange, this.minDate, this.maxDate, this.onlyCalendar),
      ]),
    );
    this.inputWidth = this.rangeForm.controls.range.value.length;

    this.rangeForm.valueChanges.subscribe((value) => {
      const range = value.range;
      if (this.rangeForm.valid) {
        const dates = range.split(' - ');
        this.dateRange = new DateRange<Moment>(moment(dates[0]), moment(dates[1]));
        this.rangeType = { current: TimeRangeType.CUSTOM, original: TimeRangeType.CUSTOM };
        this.quantity = { current: 0, original: 0 };

        if (!this.isOpen) {
          this.originalDateRange = new DateRange<Moment>(moment(dates[0]), moment(dates[1]));
        }
      }
    });
  }

  public openSelector(event: Event): void {
    event.stopPropagation();
    event.preventDefault();

    if (!this.isOpen && !this.rangeForm.valid) {
      this.setOriginalInput();
    }

    this.changeDisplayedInput('date', false);
    this.isOpen = !this.isOpen;
  }

  public revertChanges(): void {
    this.isOpen = !this.isOpen;
    this.dateRange = new DateRange(this.originalDateRange.start, this.originalDateRange.end);
    this.rangeType.current = this.rangeType.original;
    this.quantity.current = this.quantity.original;
    this.setOriginalInput();
    this.changeDisplayedInput('name');
  }

  public saveChanges(): void {
    this.isOpen = !this.isOpen;
    this.originalDateRange = new DateRange(this.dateRange.start, this.dateRange.end);
    this.rangeType.original = this.rangeType.current;
    this.quantity.original = this.quantity.current;
    this.changeDisplayedInput('name');
    this.dateRangeChange.emit(this.dateRange);
  }

  public rangeChange(range: DateRange<Moment>): void {
    this.dateRange = range;
    this.rangeForm.setValue({ range: range.start?.format(this.dateFormat) + ' - ' + range.end?.format(this.dateFormat) });
  }

  public changeDisplayedInput(type: 'date' | 'name' = 'date', focus?: boolean): void {
    this.focused = type;
    if (type === 'date' && focus) {
      this.dateInput?.focus();
    }
  }

  private setOriginalInput(): void {
    this.rangeForm.setValue({
      range: this.originalDateRange.start?.format(this.dateFormat) + ' - ' + this.originalDateRange.end?.format(this.dateFormat),
    });
  }

  public unfocusInput(): void {
    if (this.rangeForm.valid) {
      this.rangeForm
        .get('range')
        ?.setValue(this.dateRange.start?.format(this.dateFormat) + ' - ' + this.dateRange.end?.format(this.dateFormat));
      this.dateRangeChange.emit(this.dateRange);
    }
  }
}
