import { PagedData } from '../paged-data';
import { genericMap, Omitter } from './generic-map';

export type DaoType = Record<string, any>;
export type DtoType = Record<string, any>;

export interface SyncDtoMapper<Dao extends DaoType, Dto extends DtoType> {
  fromPagedDao(pagedData: PagedData<Dao>): PagedData<Dto>;

  fromDao(dao: Dao): Dto;

  fromDao(dao: Dao | null): Dto | null;

  toDao(dto: Dto): Dao;

  toDao(dto: Dto | null): Dao | null;
}

export class DefaultSyncDtoMapper<Dao extends DaoType, Dto extends DtoType> implements SyncDtoMapper<Dao, Dto> {
  constructor(
    protected dtoSample: Dto,
    protected daoSample: Dao,
  ) {}

  public fromDao(dao: Dao): Dto;
  public fromDao(dao: Dao | null): Dto | null;
  public fromDao(dao: Dao | null): Dto | null {
    if (dao) {
      return this.fromDaoUnsafe(dao);
    } else {
      return null;
    }
  }

  public toDao(dto: Dto): Dao;
  public toDao(dto: Dto | null): Dao | null;
  public toDao(dto: Dto | null): Dao | null {
    if (dto) {
      return genericMap<Dto, Dao>(dto, this.getToAddDao(dto), this.daoSample);
    } else {
      return null;
    }
  }

  public fromPagedDao(pagedData: PagedData<Dao>): PagedData<Dto> {
    return {
      //in pagedData content no null values are allowed
      content: pagedData?.content?.map((dao) => this.fromDaoUnsafe(dao)),
      totalPages: pagedData?.totalPages,
      totalElements: pagedData?.totalElements,
      number: pagedData?.number,
      size: pagedData?.size,
      first: pagedData?.first,
      last: pagedData?.last,
    };
  }

  protected getToAddDto(dao: Dao): Omitter<Dao, Dto> {
    const toAdd: Partial<Dto> = {};

    for (const key in this.dtoSample) {
      if (!(key in dao)) {
        toAdd[key] = this.dtoSample[key];
      }
    }

    return toAdd as Omitter<Dao, Dto>;
  }

  protected getToAddDao(dto: Dto): Omitter<Dto, Dao> {
    const toAdd: Partial<Dao> = {};

    for (const key in this.daoSample) {
      if (!(key in dto)) {
        toAdd[key] = this.daoSample[key];
      }
    }

    return toAdd as Omitter<Dto, Dao>;
  }

  protected fromDaoUnsafe(dao: Dao): Dto {
    return genericMap<Dao, Dto>(dao, this.getToAddDto(dao), this.dtoSample);
  }
}
