import { Injectable } from '@angular/core';
import { Action, ResourceDefinition, ResourceType, SecureAction } from '@deprecated/api-interfaces';
import { BehaviorSubject, Observable, firstValueFrom, of } from 'rxjs';
import { share, take, tap } from 'rxjs/operators';
import { ActionsHttpRepository } from '../repositories';
import { RolesService } from './roles.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ActionsService {
  public selectionMode$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private allActions: Action[] = [];

  private resourceActions = new Map<string, string[]>();
  private pendingActions = new Map<string, Observable<string[]>>();

  constructor(
    private userService: UserService,
    private actionRepo: ActionsHttpRepository,
    private roleService: RolesService,
  ) {}

  public fetchResourceActions(resourceId: string, resourceType: ResourceType): Observable<string[]> {
    if (this.resourceActions.has(resourceId)) {
      return of(this.resourceActions.get(resourceId));
    } else if (this.pendingActions.get(resourceId)) {
      return this.pendingActions.get(resourceId);
    } else {
      const observable = this.actionRepo.getRolesByResourceIdAndType(resourceId, resourceType).pipe(
        tap((roles) => {
          this.resourceActions.set(resourceId, roles);
          this.pendingActions.delete(resourceId);
        }),
        share(),
      );
      this.pendingActions.set(resourceId, observable);
      return observable;
    }
  }

  public getAllActions(): Observable<Action[]> {
    if (this.allActions.length > 0) {
      return of(this.allActions);
    }

    return this.actionRepo.getAll().pipe(
      take(1),
      tap((actions: Action[]) => (this.allActions = actions)),
    );
  }

  public getActionsByRightId(id: string): Observable<Action[]> {
    return this.actionRepo.getByRightId(id);
  }

  public create(action: Action): Observable<Action> {
    return this.actionRepo.create(action);
  }

  public update(action: Action): Observable<Action> {
    return this.actionRepo.update(action);
  }

  public delete(actionId: string): Observable<void> {
    return this.actionRepo.deleteAction(actionId);
  }

  public assignRight(actionId: string, rightId: string): Observable<{ actionId: string; requiredRights: Array<string> }> {
    return this.actionRepo.assignRight(actionId, rightId);
  }

  public unassignRight(actionId: string, rightId: string): Observable<{ actionId: string; requiredRights: Array<string> }> {
    return this.actionRepo.unassignRight(actionId, rightId);
  }

  public hasAction(secureAction: string | string[] | SecureAction | SecureAction[], resource?: ResourceDefinition): boolean {
    if (!secureAction) {
      return true;
    }

    const key = SecureAction.convertToString(secureAction);

    let actionSet = this.userService.getUserActions();
    const useResourceRoles = resource && this.userService.isResourceBasedRolesActive();

    if (useResourceRoles) {
      if (this.resourceActions.has(resource.id)) {
        actionSet = this.resourceActions.get(resource.id);
      } else {
        this.fetchResourceActions(resource.id, resource.type).subscribe();
        actionSet = [];
      }
    }

    return this.checkAction(key, actionSet, useResourceRoles);
  }

  public async hasActionAsync(
    secureAction: string | string[] | SecureAction | SecureAction[],
    resource?: ResourceDefinition,
  ): Promise<boolean> {
    if (!secureAction) {
      return true;
    }

    const key = SecureAction.convertToString(secureAction);

    let actionSet = this.userService.getUserActions();
    const useResourceRoles = resource && this.userService.isResourceBasedRolesActive();

    if (useResourceRoles) {
      if (this.resourceActions.has(resource.id)) {
        actionSet = this.resourceActions.get(resource.id);
      } else {
        actionSet = await firstValueFrom(this.fetchResourceActions(resource.id, resource.type));
      }
    }

    return this.checkAction(key, actionSet, useResourceRoles);
  }

  private checkAction(key: string | string[], actionSet: string[], useResourceRoles: boolean): boolean {
    if (key instanceof Array) {
      if (key.length === 2) {
        return this.roleService.showContents(key[0], key[1], useResourceRoles ? actionSet : undefined);
      }
    } else if (actionSet) {
      if (key.includes('|')) {
        const index: number = actionSet.findIndex((action: string) => action === key);
        return index >= 0;
      } else {
        const index: number = actionSet.findIndex((action: string) => action.startsWith(key));
        return index >= 0;
      }
    }

    return false;
  }
}
