import { DeepPath, GeneratedObjectTree, NestedObject } from '../nested-object';

export abstract class BaseFeatureTranslationService<T extends NestedObject, P extends DeepPath<T>> {
  protected featureTranslations: GeneratedObjectTree<T, P>;

  constructor() {
    this.featureTranslations = this.extractFeatureTranslations();
  }

  public getTranslations(): GeneratedObjectTree<T, P> {
    return this.featureTranslations;
  }

  protected extractFeatureTranslations(): GeneratedObjectTree<T, P> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const constructor = this.constructor as any;
    if (typeof constructor.getFeatureTranslations === 'function') {
      return constructor.getFeatureTranslations();
    } else {
      throw new Error('The subclass must implement a static getFeatureTranslations method.');
    }
  }
}
