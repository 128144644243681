// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface EntityEvent<T> {
  topic: string;
  username: string;
  channel: 'REDIS_PUB_SUB' | 'PULSAR';
}

export interface CreateEvent<T> extends EntityEvent<T> {
  new: T;
}

export interface ModifyEvent<T> extends EntityEvent<T> {
  old: T;
  new: T;
}

export interface DeleteEvent<T> extends EntityEvent<T> {
  old: T;
}
