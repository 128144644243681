export enum SecureActionKey {
  ADMIN_EDIT_USER_GROUPS = 'admin:users:groups|edit',
  DEVICE_EDIT_GROUPS = 'device:edit|edit_groups',

  NAVIGATION_DEVICES = 'device:overview',

  NAVIGATION_LOCATION_MENU = 'locations',
  NAVIGATION_LOCATION = 'locations|view',
  NAVIGATION_LOCATIONS_MAP = 'locations:map',

  NAVIGATION_MONITORING = 'monitoring',
  NAVIGATION_MONITORING_ACTIVE_ALARMS = 'monitoring:alarms:pending',
  NAVIGATION_MONITORING_CONFIGURED_ALARMS = 'monitoring:alarms:configuration',

  NAVIGATION_SHOP = 'shop|view',

  NAVIGATION_ADMIN = 'admin',
  NAVIGATION_ADMIN_USERS = 'admin:users',

  NAVIGATION_ADMIN_USER_MANAGEMENT = 'user_management',
  NAVIGATION_ADMIN_GROUPS = 'admin:groups',
  NAVIGATION_ADMIN_RIGHTS = 'admin:rights',
  NAVIGATION_ADMIN_PERMISSION_GROUPS = 'admin:permission_groups',

  NAVIGATION_ADMIN_SENSORS = 'admin:sensors',
  NAVIGATION_ADMIN_APPTOKENS = 'admin:apptoken',
  NAVIGATION_ADMIN_DATA_RATES = 'admin:data_rates',
  NAVIGATION_ADMIN_ACTION_OVERLAY = 'admin:action_overlay',
  NAVIGATION_ADMIN_AUDIT = 'admin:audit',
  NAVIGATION_ADMIN_REQUEST = 'admin:request',
  NAVIGATION_ADMIN_TENANT = 'admin:tenant',

  NAVIGATION_ADMIN_OPCUA_SERVER = 'admin:opcua:servers',
  NAVIGATION_ADMIN_OPCUA_CLIENT = 'admin:opcua:clients',

  NAVIGATION_SETTINGS = 'settings',
  NAVIGATION_SETTINGS_MODELS = 'settings:model',
  NAVIGATION_SETTINGS_DASHBOARD_TEMPLATE = 'settings:dashboard',
  NAVIGATION_SETTINGS_UNIT_MAPPING = 'settings:unit_mapping',
  NAVIGATION_SETTINGS_SENSOR_TYPES = 'settings:sensor_types',
  NAVIGATION_SETTINGS_SENSOR_TYPES_V2 = 'settings:sensor_types_v2',
}
