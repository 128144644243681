import { Component, computed, input, output } from '@angular/core';
import { BaseModule } from '../base';
import { NgForOf } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'spx-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['paginator.component.scss'],
  standalone: true,
  imports: [BaseModule, NgForOf],
})
export class PaginatorComponent {
  public pageSize = input<number>(12);
  public pageNumber = input.required<number>();
  public totalElements = input.required<number>();
  public lastPageNumber = computed(() => Math.floor(this.totalElements() / this.pageSize()));
  public pageNumbers = computed(() =>
    [this.pageNumber() - 1, this.pageNumber(), this.pageNumber() + 1].filter((n) => n >= 0 && n <= this.lastPageNumber()),
  );
  public isFirstPage = computed(() => this.pageNumber() === 0);
  public isLastPage = computed(() => this.pageNumber() === this.lastPageNumber());

  public pageChange = output<PageEvent>();

  public changePageIndex(pageIndex: number): void {
    this.pageChange.emit({ pageSize: this.pageSize(), pageIndex } as PageEvent);
  }
}
