import { SubTree } from '../nested-object';

export class BaseEndpointService<T, P extends string | null | undefined> {
  protected readonly endpoints: SubTree<T, P>;

  /**
   * Constructs a new instance of the `BaseEndpointService` class.
   *
   * @param apiPrefix - A list of API prefixes to be used for generating the endpoints.
   */
  constructor(...apiPrefix: (string | undefined)[]) {
    this.endpoints = this.getApiEndpoints(...apiPrefix);
  }

  /**
   * Retrieves the generated endpoints (instance method).
   *
   * @returns The endpoints generated by the implementing class.
   */
  public getEndpoints(): SubTree<T, P> {
    return this.endpoints;
  }

  /**
   * Generates the endpoints using the static method in the subclass.
   * The subclass is expected to provide the logic for generating the endpoints.
   *
   * @param apiPrefix - The API prefix to customize the endpoint generation.
   * @returns The generated endpoints.
   */
  protected getApiEndpoints(...apiPrefix: (string | undefined)[]): SubTree<T, P> {
    /**
     * access the getEndpoints static method of the subclass
     */

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const constructor = this.constructor as any;
    if (typeof constructor.getEndpoints === 'function') {
      return constructor.getEndpoints(...apiPrefix);
    } else {
      throw new Error('The subclass must implement a static getEndpoints method.');
    }
  }
}
