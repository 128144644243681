import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TenantService } from '../../services';

@Component({
  selector: 'shared-device-icons',
  templateUrl: './device-icons.component.html',
  standalone: false,
})
export class DeviceIconsComponent implements OnInit {
  @Input() device!: { timeoutDuration?: number; configuration: Record<string, string>; status: string };
  @Input() isOnline!: boolean;

  public timeOutDuration!: number;
  public timeOutUnit!: string;

  constructor(
    private tenantService: TenantService,
    private translateService: TranslateService,
  ) {}

  public ngOnInit(): void {
    const durationInNanoSec = this.device.timeoutDuration;
    const returnObj = this.tenantService.convertTimeoutDurationDynamic(durationInNanoSec);
    if (returnObj) {
      this.timeOutDuration = returnObj.value;
      this.timeOutUnit = this.translateService.instant('ADMINISTRATION.TENANT.FEATURES.TIMEOUT_DURATION.' + returnObj.unit.toUpperCase());
    }
  }
}
