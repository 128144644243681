import {SecureAction} from '../secure-actions';

/**
 * @deprecated Please move your code to the specific domain and use DAO, DTO and model separation
 */
export class UserManagementSecureAction extends SecureAction {
  // Hierarchy tree
  public static readonly DELETE: SecureAction = new UserManagementSecureAction('user_management|delete');
  public static readonly EDIT: SecureAction = new UserManagementSecureAction('user_management|edit');
  public static readonly VIEW: SecureAction = new UserManagementSecureAction('user_management|view');

  //Details
  public static readonly VIEW_DETAILS: SecureAction = new UserManagementSecureAction('user_management:details|view');

  //Locations
  public static readonly LOCATIONS: SecureAction = new UserManagementSecureAction('user_management:locations');
  public static readonly VIEW_LOCATIONS: SecureAction = new UserManagementSecureAction('user_management:locations|view');

  //Notifications
  public static readonly NOTIFICATIONS: SecureAction = new UserManagementSecureAction('user_management:notifications');
  public static readonly VIEW_NOTIFICATIONS: SecureAction = new UserManagementSecureAction('user_management:notifications|view');
  public static readonly EDIT_NOTIFICATIONS: SecureAction = new UserManagementSecureAction('user_management:notifications|edit');
  public static readonly VIEW_ALL_ALARMS_NOTIFICATIONS: SecureAction = new UserManagementSecureAction(
    'user_management:notifications:types|all_alarms',
  );
  public static readonly VIEW_ERROR_NOTIFICATIONS: SecureAction = new UserManagementSecureAction(
    'user_management:notifications:types|error',
  );
  public static readonly VIEW_STATUS_REPORT_NOTIFICATIONS: SecureAction = new UserManagementSecureAction(
    'user_management:notifications:types|status_report',
  );
  public static readonly VIEW_WARNING_NOTIFICATIONS: SecureAction = new UserManagementSecureAction(
    'user_management:notifications:types|warning',
  );
  public static readonly VIEW_INFO_NOTIFICATIONS: SecureAction = new UserManagementSecureAction('user_management:notifications:types|info');
  public static readonly DELETE_TEMPLATE_NOTIFICATIONS: SecureAction = new UserManagementSecureAction(
    'user_management:notifications:template|edit',
  );
  public static readonly EDIT_TEMPLATE_NOTIFICATIONS: SecureAction = new UserManagementSecureAction(
    'user_management:notifications:template|delete',
  );
  public static readonly VIEW_TEMPLATE_NOTIFICATIONS: SecureAction = new UserManagementSecureAction(
    'user_management:notifications:template|view',
  );
  public static readonly VIEW_SAFETY_STOP_NOTIFICATIONS: SecureAction = new UserManagementSecureAction(
    'user_management:notifications:types|safety_stop',
  );
  public static readonly VIEW_EMAIL_NOTIFICATIONS: SecureAction = new UserManagementSecureAction('user_management:notifications|email');
  public static readonly VIEW_SMS_NOTIFICATIONS: SecureAction = new UserManagementSecureAction('user_management:notifications|sms');

  // Users
  public static readonly USERS: SecureAction = new UserManagementSecureAction('user_management:users');
  public static readonly VIEW_USERS: SecureAction = new UserManagementSecureAction('user_management:users|view');
  public static readonly ADD_ADMIN: SecureAction = new UserManagementSecureAction('user_management:users|add_admin');
  public static readonly REMOVE_ADMIN: SecureAction = new UserManagementSecureAction('user_management:users|remove_admin');
  public static readonly ADD_USER_TO_TEAM: SecureAction = new UserManagementSecureAction('user_management:teams|add_user');
  public static readonly REMOVE_USER_FROM_TEAM: SecureAction = new UserManagementSecureAction('user_management:teams|remove_user');
  public static readonly USER_CREATE: SecureAction = new UserManagementSecureAction('user_management:users|create');

  // Service accounts
  public static readonly SERVICE_ACCOUNTS: SecureAction = new UserManagementSecureAction('user_management:service_accounts');
  public static readonly VIEW_SERVICE_ACCOUNTS: SecureAction = new UserManagementSecureAction('user_management:service_accounts|view');
  public static readonly ADD_SERVICE_ACCOUNT: SecureAction = new UserManagementSecureAction('user_management:service_accounts|add');
  public static readonly EDIT_SERVICE_ACCOUNT: SecureAction = new UserManagementSecureAction('user_management:service_accounts|edit');
  public static readonly CHANGE_PASSWORD_SERVICE_ACCOUNT: SecureAction = new UserManagementSecureAction(
    'user_management:service_accounts|change_password',
  );
  public static readonly DELETE_SERVICE_ACCOUNT: SecureAction = new UserManagementSecureAction('user_management:service_accounts|delete');

  // User - detail
  public static readonly USER_DETAIL_VIEW: SecureAction = new UserManagementSecureAction('user_management:users:detail|view');
  public static readonly USER_DETAIL_EDIT: SecureAction = new UserManagementSecureAction('user_management:users:detail|edit');
  public static readonly USER_DETAIL_DELETE: SecureAction = new UserManagementSecureAction('user_management:users:detail|delete');
  public static readonly USER_DETAIL_RESET_PASSWORD: SecureAction = new UserManagementSecureAction(
    'user_management:users:detail|reset_password',
  );
  public static readonly USER_DETAIL_EDIT_PERMISSION_GROUP: SecureAction = new UserManagementSecureAction(
    'user_management:users:detail|edit_permission_group',
  );
  public static readonly USER_DETAIL_EDIT_KEYCLOAK_REALM: SecureAction = new UserManagementSecureAction('admin:keycloak:realm|edit');
  public static readonly USER_DETAIL_IMPERSONATE: SecureAction = new UserManagementSecureAction('user_management:users:detail|impersonate');
  public static readonly USER_DETAIL_METADATA_VIEW: SecureAction = new UserManagementSecureAction(
    'user_management:users:detail:metadata|view',
  );
  public static readonly USER_DETAIL_METADATA_SAVE: SecureAction = new UserManagementSecureAction(
    'user_management:users:detail:metadata|save',
  );
  public static readonly USER_DETAIL_CLASSIFICATIONS_VIEW: SecureAction = new UserManagementSecureAction(
    'user_management:users:detail:classifications|view',
  );
  public static readonly USER_DETAIL_CLASSIFICATIONS_SAVE: SecureAction = new UserManagementSecureAction(
    'user_management:users:detail:classifications|save',
  );
  public static readonly USER_DETAIL_TREE_VIEW: SecureAction = new UserManagementSecureAction('user_management:users:detail:tree|view');
  public static readonly USER_DETAIL_DEVICE_VIEW: SecureAction = new UserManagementSecureAction(
    'user_management:users:detail:devices|view',
  );
  public static readonly USER_DETAIL_DEVICE_TREE_PATH_VIEW: SecureAction = new UserManagementSecureAction(
    'user_management:users:detail:devices:tree_path|view',
  );
  public static readonly USER_DETAIL_MIGRATE_USER_KEYCLOAK: SecureAction = new UserManagementSecureAction(
    'user_management:users:keycloak|migrate',
  );
  public static readonly USER_DETAILS_SWITCH_TENANT: SecureAction = new UserManagementSecureAction(
    'user_management:users:keycloak:tenant|sync',
  );

  // User - groups
  public static readonly USER_GROUPS_VIEW: SecureAction = new UserManagementSecureAction('user_management:users:user_groups|view');
  public static readonly USER_GROUPS_CREATE: SecureAction = new UserManagementSecureAction('user_management:users:user_groups|create');
  public static readonly USER_GROUPS_DELETE: SecureAction = new UserManagementSecureAction('user_management:users:user_groups|delete');
  public static readonly USER_GROUPS_CHANGE: SecureAction = new UserManagementSecureAction('user_management:users:user_groups|change');

  // Assets
  public static readonly DEVICES: SecureAction = new UserManagementSecureAction('user_management:devices');
  public static readonly VIEW_DEVICES: SecureAction = new UserManagementSecureAction('user_management:devices|view');
  public static readonly VIEW_DEVICES_TREE_PATH: SecureAction = new UserManagementSecureAction('user_management:devices:tree_path|view');
  public static readonly ADD_DEVICE_TO_TEAM: SecureAction = new UserManagementSecureAction('user_management:teams|add_asset');
  public static readonly REMOVE_DEVICE_FROM_TEAM: SecureAction = new UserManagementSecureAction('user_management:teams|remove_asset');

  // Edit Organizations
  public static readonly UM_ORGANIZATIONS_EDIT_TAG: SecureAction = new UserManagementSecureAction(
    'user_management:organizations|edit_tags',
  );

  // Device groups
  public static readonly VIEW_DEVICE_GROUPS: SecureAction = new UserManagementSecureAction('user_management:devices:device_groups|view');
  public static readonly UPDATE_DEVICE_GROUPS: SecureAction = new UserManagementSecureAction(
    'user_management:devices:device_groups|update',
  );
  public static readonly DELETE_DEVICE_GROUPS: SecureAction = new UserManagementSecureAction(
    'user_management:devices:device_groups|delete',
  );
  public static readonly ADD_DEVICE_TO_DEVICE_GROUPS: SecureAction = new UserManagementSecureAction(
    'user_management:devices:device_groups|add_device',
  );
  public static readonly REMOVE_DEVICE_FROM_DEVICE_GROUPS: SecureAction = new UserManagementSecureAction(
    'user_management:devices:device_groups|remove_device',
  );

  private constructor(name: string) {
    super(name);
  }
}
