import { Injectable } from '@angular/core';
import { GoogleAutocompleteOptions } from './google-autocomplete.options';

@Injectable({
  providedIn: 'root',
})
export class GoogleAutocompleteService {
  private verifyGooglePersistence: boolean;
  private defaultOptions: GoogleAutocompleteOptions = {};

  setOptions(options: GoogleAutocompleteOptions): void {
    this.defaultOptions = { ...this.defaultOptions, ...options };
  }

  getOptions(): GoogleAutocompleteOptions {
    return this.defaultOptions;
  }

  enableGooglePersistenceCheck(): void {
    this.verifyGooglePersistence = true;
  }

  getGooglePersistenceCheck(): boolean {
    return this.verifyGooglePersistence;
  }
}
