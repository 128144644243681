import { Injectable } from '@angular/core';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  roles: string[];

  constructor(private userService: UserService) {}

  showContentsForLocations(locations: string[], action = ''): boolean {
    let found = false;
    if (locations) {
      locations.forEach((location) => {
        if (!found && this.showContents(location, action)) {
          if (RolesService.isBetaLocation(location)) {
            found = this.showBetaFeatures();
          } else {
            found = true;
          }
        }
      });
    }
    return found;
  }

  showContents(location: string, action = '', roles?: string[]): boolean {
    if (this.userService.getUser()) {
      this.roles = roles || this.userService.getUser().roles;

      if (this.validateAdminAction(location)) {
        return true;
      }

      if (this.validateSupportAction(location)) {
        return true;
      }
      // filter my roles only to location and map it as actions
      // underscore because DEVICE_VIEW and DEVICES_VIEW
      const permittedActionsForLocation = this.roles
        .filter((x) => x.includes('_' + location.toUpperCase() + '_'))
        .map((x) => x.split('_').slice(-1).pop());

      if (permittedActionsForLocation.includes(action.toUpperCase())) {
        return true;
      } else if (permittedActionsForLocation.includes('ADMIN')) {
        return true;
      } else if (action.toLowerCase() === 'view') {
        return (
          permittedActionsForLocation.includes('DELETE') ||
          permittedActionsForLocation.includes('EDIT') ||
          permittedActionsForLocation.includes('VIEW')
        );
      } else if (action.toLowerCase() === 'edit') {
        return permittedActionsForLocation.includes('DELETE') || permittedActionsForLocation.includes('EDIT');
      } else if (action.toLowerCase() === 'delete') {
        return permittedActionsForLocation.includes('DELETE');
      } else {
        return action === '';
      }
    }
  }

  private validateSupportAction(location: string): boolean {
    const hasSupportRole = this.roles.includes('ROLE_SUPPORT');
    const isOneOfLocations = ['devices/edit', 'administrator', 'devices'].includes(location);
    return hasSupportRole && isOneOfLocations;
  }

  validateAdminAction(location: string) {
    if (this.roles.find((a) => a.includes('ROLE_ADMIN'))) {
      return true;
    }
    const adminRoles = this.roles.filter((role) => role.includes('ADMIN'));
    const result = adminRoles.filter((role) => role.includes(location.toUpperCase().split('_').shift()));
    return result.length !== 0;
  }

  private static isBetaLocation(location: string) {
    const betaLocations = ['maintenance', 'monitoring_template', 'monitoring_rule', 'event_log_list'];
    return betaLocations.includes(location);
  }

  showBetaFeatures() {
    const user = this.userService.getUser();

    if (user) {
      this.roles = user.roles;
      return this.roles.some((r) => r.includes('ROLE_BETA'));
    }

    return false;
  }

  isPlatformAdmin(): boolean {
    const user = this.userService.getUser();

    return user?.roles.some((r) => r.includes('ROLE_PLATFORM_ADMIN'));
  }
}
